var generic = generic || {};

/**
 * Template.js
 *
 * @memberOf generic
 *
 * @class TemplateSingleton
 * @namespace generic.template
 *
 * @requires object literal with parameters
 *
 * @param path attribute as a literal key is required
 * @example "/templates/cart-overlay.tmpl",
 *
 * @param {string} templateString takes first priority
 * @example templateString:'#{product.url} some-page-markup-with-#{product.url}'
 *
 * @param {boolean} forceReload
 *
 * @param {function} callback
 * @example
 *
 * callback:function(html) {
 *    // Front-End Resolution
 *    $('#container').html(html);
 * }
 *
 * @param {object} query object hash with object-literals, array-literals that can be nested
 * @example example structure
 * query: {
 *    a:'',b:{},c:[],d:{[]} // keys rooted to named parent if object or array-objects are nested
 * }
 *
 * @param {object} Hash of string-literals with string values that map to the template
 * @example
 *
 * object: {
 *    'product.PROD_RGN_NAME':'replacement',
 *    SOME_VAR:'replacement'
 * }
 *
 * @example Usage
 *
 * generic.template.get({
 *    path:"/some/path/to/template.tmpl",
 *    ...
 * });
 *
 * @param {HTML} (optional) Markup based inline template
 * @required The path attribute must match the path key passed to the get method.
 *
 * @example Inline Template Example
 *
 * <!-- -------------------------- Inline Template ------------------------------ -->
 *
 * <script type="text/html" class="inline-template" path="templates/foo.tmpl">"
 *         <div>#{FIRST_NAME}</div>
 *         <div>#{SECOND_NAME}</div>
 * </script>
 *
 * Inline Templates : Valid inline template via script tag in this format, aside
 * from the templateString parameter, will be the first candidate for the template,
 * then the cache, then ajax.
 *
 *
 * @returns {object} An object that refers to a singleton which provides
 * the primary get api method.
 *
 */


generic.template  = ( function() {

    var that = {};
    var templateClassName = ".inline-template";
    var templates = {};

    /**
     * This method loads a pre-interpolation template into the object's internal cache. This cache is checked before attempting to pull the template from the DOM or load it via Ajax.
     * @param (String) key The name that is used to retrieve the template from the internal cache. Typically mathces the path for Ajax-loaded templates.
     * @param (String) html The non-interpoltaed content of the template.
     * @returns (Strin) the HTML that was originally passed in
     * @private
     */
    var setInternalTemplate = function(key, html) {
        templates[key] = html;
        return html;
    };

    var getInternalTemplate = function(key) {
        return templates[key];
    };

    var returnTemplate = function(args) {
        if (typeof args.object === "object") {
            var html = interpolate(args.template, args.object);
        }else{
			var html = args.template;
		}
        if (typeof args.callback === "function") {
            args.callback(html);
        }
    };

    var interpolate = function(template, obj) {
        var obj = obj || {};
        var tmpl = template, Lre = new RegExp("\#\{"), Rre = new RegExp("\}"), tmplA = [], temp, lft, rght;

        tmplA = tmpl.replace(/[\r\t\n]/g," ").split(Lre); // array of (.+?)} with '}' marking key vs rest of doc

        var returnString = "";
        for(var x = 0; x < tmplA.length; x++) {
            var chunk = tmplA[x];
            var splitChunk = chunk.split(Rre);

			// FIXME TODO: Embarrassingly ham handed approach to setting url_domain template variable for IE (bug i73662)
			//  Needs someone more familiar with javascript to find out why this error only occurs in IE
			//	 with the url_domain object value set anywhere but here (setting it elsewhere works fine in FF)
			if (splitChunk[0] == 'url_domain') {
				splitChunk[1] = 'http://' + document.location.hostname;
			}
            if (typeof splitChunk[1] !== "undefined") { // close tag is found
                // First check array notation for property names with spaces
                // Then check object notation for deep references
                var valueToInsert = eval("obj['" + splitChunk[0] +"']" ) || eval("obj." + splitChunk[0] );
                if (typeof valueToInsert === "undefined" || valueToInsert === null) {
                    valueToInsert = '';
                }
                chunk = valueToInsert.toString() + splitChunk[1];
            }
            returnString += chunk;
        }
        return returnString;
    };

    that.get = function( args ) {
        var key = args.path;
        var callback = args.callback;
        var forceReload = !!args.forceReload;
        var objectParam = args.object;
        var template = getInternalTemplate(key);

        if (template && !forceReload) {  // internal template found and OK to use cache
            returnTemplate({
                template: template,
                object: objectParam,
                callback: args.callback
            })
        } else {  // no internal template found or not OK to use cache
            // attempt to retrieve from DOM
            var matchingTemplateNode = null;
            $(templateClassName).each( function() {
                if ( $(this).html() && ( $(this).attr("path")==key) ) {
                    matchingTemplateNode = this;
                }
            });
            if (matchingTemplateNode) { // inline template found in DOM
                template = setInternalTemplate( key, $(matchingTemplateNode).html() );
                returnTemplate({
                    template: template,
                    object: args.object,
                    callback: args.callback
                })
            } else { // not found inline


                $.ajax({
                    url: key,
                    context: this, // bind (.bind onSuccess callback)
                    data: args.urlparams,
                    success: function(data, textStatus, jqXHR){
                        template = setInternalTemplate( key, jqXHR.responseText);
                        returnTemplate({
                            template: template,
                            object: args.object,
                            callback: args.callback
                        })
                    }
                });
            }
        }

    };

    that.post = function( args ) {
        var options = {
            type: "POST",
            urlparams: (args.data || args.object || args.urlparams),
            forceReload: true
        };
        var args = $.extend(options,args);
        this.get(args);
    };
    return that;

})();
